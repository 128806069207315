import { JSX, Show } from "solid-js";
import c from "class-c";

import AppRouter from "@repo/utils-solid/AppRouter";

import WrapIf from "../flow/WrapIf";
import BackButton from "../navigation/BackButton";

import Constraints from "./Constraints";

import styles from "./PageLayout.module.scss";

declare namespace PageLayout {
  type Props = Partial<Constraints.Props> & {
    title: string;
    subtitle?: JSX.Element;
    action?: JSX.Element;
    back?: boolean;
    // TODO: Remove all usages of onBack after we have proper history tracking
    // and are able to pop flows
    onBack?: () => void;
    class?: string;
    fillHeight?: boolean;
    hero?: JSX.Element;
  };
}

function PageLayout({
  title,
  subtitle,
  action,
  back,
  onBack,
  class: className,
  children,
  fillHeight,
  hero,
  ...props
}: D<PageLayout.Props>) {
  const placeAction: WrapIf.Props["wrap"] = (children) => (
    <div class="row-center">
      <div class="expand">{children()}</div>
      <div class="text:regular">{action}</div>
    </div>
  );

  return (
    <div
      class={c`${styles["page-layout"]} ${fillHeight && styles["fill-height"]} py-3 ${className}`}
    >
      <Constraints {...props}>
        {back && (
          <div class="mb-3">
            <WrapIf when={action} wrap={placeAction}>
              <BackButton
                onClick={() => {
                  if (onBack) return onBack();
                  AppRouter.back.or("..");
                }}
              />
            </WrapIf>
          </div>
        )}
        <Show when={hero}>
          <div class="center-child mb-3">{hero}</div>
        </Show>
        <header class="text:heading-3">
          <WrapIf when={action && !back} wrap={placeAction}>
            {title}
          </WrapIf>
        </header>
        {subtitle && (
          <div class="text:small color:moderate mt-0.5">{subtitle}</div>
        )}
        <main class="mt-3">{children}</main>
      </Constraints>
    </div>
  );
}

export default PageLayout;
