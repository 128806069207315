import { ComponentProps, JSX } from "solid-js";
import c from "class-c";
import { eventHandler, PropsFor } from "solid-u";

import ContainerButton from "../buttons/ContainerButton";

declare namespace BackButton {
  type Props = ContainerButton.Props & {
    class?: string;
  };
}

function BackButton(props: BackButton.Props) {
  return (
    <ContainerButton
      {...props}
      class={c`fit-content icon:medium ${props.class}`}
    >
      
    </ContainerButton>
  );
}

declare namespace BackButton.WithContent {
  type Props = ComponentProps<"div"> & {
    propsFor?: { button?: BackButton.Props };
    onBack(): void;
    children: JSX.Element;
  };
}

BackButton.WithContent = ({
  class: className,
  onBack,
  propsFor: _propsFor,
  children,
  ...props
}: D<BackButton.WithContent.Props>) => {
  const propsFor = PropsFor.createHandler(() => _propsFor);

  return (
    <div class={c`row-center ${className}`} {...props}>
      <BackButton
        {...propsFor?.("button")}
        class={c`mr-1 ${propsFor?.("button").class}`}
        onClick={(e) => {
          onBack?.();
          eventHandler(propsFor?.("button").onClick)(e);
        }}
      />
      <div>{children}</div>
    </div>
  );
};

export default BackButton;
