import { JSX, Show } from "solid-js";

import USolid from "@repo/utils-solid/USolid";

declare namespace WrapIf {
  type Props = {
    when: any;
    wrap(children: () => JSX.Element): JSX.Element;
    children: JSX.Element;
  };
}

function WrapIf({ when, wrap, children }: D<WrapIf.Props>) {
  const resolvedChildren = USolid.children(() => children);

  return (
    <Show when={when} fallback={resolvedChildren()}>
      {wrap(resolvedChildren)}
    </Show>
  );
}

export default WrapIf;
