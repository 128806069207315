import { Accessor, createEffect, createSignal } from "solid-js";

import UPromise from "@repo/utils/UPromise";

export default function createAsync<T, U extends T | undefined>(
  fn: () => UPromise.Maybe<T>,
  defaultValue?: U,
) {
  const [value, setValue] = createSignal<T>(defaultValue as T);
  const [loading, setLoading] = createSignal(true);
  const [error, setError] = createSignal<any>();

  createEffect(async () => {
    setLoading(true);
    try {
      const value = await fn();
      setValue(() => value);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  });

  return Object.assign(value as Accessor<T | U>, { loading, error });
}
