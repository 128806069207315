import { Match, Switch } from "solid-js";

import Attachment from "@repo/utils/Attachment";

import Pdf from "./Pdf";

import styles from "./AttachmentViewer.module.scss";

declare namespace AttachmentViewer {
  type Props = {
    attachment: Attachment;
  };
}

function AttachmentViewer({ attachment }: D<AttachmentViewer.Props>) {
  return (
    <Switch>
      <Match when={attachment.mimeType.startsWith("image/")}>
        <img src={attachment.url} class={styles.image} />
      </Match>
      <Match when={attachment.mimeType === "application/pdf"}>
        <Pdf url={attachment.url} withCredentials />
      </Match>
    </Switch>
  );
}

export default AttachmentViewer;
